import * as React from "react";

import { HStack, Stack, useMediaQuery } from "@chakra-ui/react";
import WalltFullLogo from "../icons/WalltFullLogo";
import StyledButton from "./StyledButton";
import HamburgerIcon from "../icons/Hamburger";
import { Link } from "react-router-dom";
import { CloseIcon } from "@chakra-ui/icons";

const Header = () => {
  const [isLargerThan800] = useMediaQuery("(min-width: 800px)");
  const [openMenu, setOpenMenu] = React.useState(false);

  return (
    <HStack
      position="fixed"
      width="100%"
      paddingX="4%"
      paddingTop="20px"
      paddingBottom="20px"
      top={0}
      zIndex={999}
      justifyContent="space-between"
      background="#080F16CC"
    >
      <HStack gap="33%">
        {!isLargerThan800 && (
          <>
            <HamburgerIcon
              width="31px"
              height="37px"
              color="white"
              onClick={() => setOpenMenu((prev) => !prev)}
            />
            {openMenu && (
              <Stack
                color="white"
                position="absolute"
                width="100vw"
                height="100vh"
                top={0}
                left={0}
                padding="9% 4%"
                spacing={10}
                backdropFilter="blur(5px)"
              >
                <CloseIcon fontSize="24px" onClick={() => setOpenMenu(false)} />
                <Stack
                  background="#10464a7d"
                  borderRadius="10px"
                  fontSize="18px"
                  width="60%"
                  padding="10% 4%"
                  spacing={6}
                >
                  <Link to="/">Home</Link>
                  <Link to="/about">About WALLT</Link>
                  <Link to="/feature">Features</Link>
                  <Link to="/contact-us">Contact Us</Link>
                </Stack>
                <Stack
                  background="#10464A"
                  borderRadius="10px"
                  width="40%"
                ></Stack>
              </Stack>
            )}
          </>
        )}
        <Link to="/">
          <WalltFullLogo width="146px" height={{ base: "39px", md: "40px" }} />
        </Link>
      </HStack>

      {isLargerThan800 ? (
        <HStack fontSize="16px" fontWeight={700} color="#A99EB9" gap={10}>
          <Link to="/about">About WALLT</Link>
          <Link to="/feature">Features</Link>
          {/* <Link>Resources</Link>
          <Link>FAQs</Link> */}
          <Link to="/contact-us">Contact Us</Link>
          <StyledButton
            py="14px"
            borderRadius="8px"
            width="133px"
            fontSize="14px"
            onClick={() => window.open("http://app.wallt.in/sign-up")}
          >
            Sign In
          </StyledButton>
        </HStack>
      ) : null}
    </HStack>
  );
};

export default Header;
