import { Box, Center, HStack, Stack, Text } from "@chakra-ui/react";
import StoryPast from "../../icons/StoryPast";
import StoryPresent from "../../icons/StoryPresent";
import StoryFeature from "../../icons/StoryFeature";
import {
  AnimatePresence,
  motion,
  MotionValue,
  useMotionValueEvent,
  useScroll,
} from "framer-motion";
import { useRef, useState } from "react";
import { useResponsive } from "../../providers/useMediaProvider";
import WalltLogo from "../../icons/WalltLogo";
import { ReactComponent as Dashboard } from "../../images/HalfDashboard.svg";
import AboutUsBgGlow from "../../icons/AboutUsBgGlow";
import Covid from "../../icons/Covid";
import Court from "../../icons/Court";
import GraphIcon from "../../icons/GraphIcon";
import KeyIcon from "../../icons/KeyIcon";
import HomeIcon from "../../icons/HomeIcon";
import DocumentIcon from "../../icons/Document";
import HeartIcon from "../../icons/HearIcon";
import HeartStroke from "../../icons/HeartStroke";
import CancerIcon from "../../icons/CancerIcon";
import AccidentIcon from "../../icons/AccidentIcon";
import FireIcon from "../../icons/FireIcon";
import VirusIcon from "../../icons/VirusIcon";

const CircleComponent = ({
  scrollYProgress,
}: {
  scrollYProgress: MotionValue<number>;
}) => {
  const { isLargerThan768 } = useResponsive();

  const [circleStyle, setCircleStyle] = useState<any>({
    transform: isLargerThan768
      ? "translate(-9%, -58%)"
      : "translate(-25.5%, -65%)",
    height: isLargerThan768 ? "70vh" : "120vh",
    background: "#080F16",
    borderRadius: "0 0 50% 50%",
  });
  const [headingStyle, setHeadingStyle] = useState<any>({
    fontSize: isLargerThan768 ? "48px" : "25px",
    transform: `translateY(0%)`,
  });
  const [subHeadingStyle, setSubHeadingStyle] = useState<any>({
    transform: `translateY(0%)`,
  });
  const [contentStyle, setContentStyle] = useState<any>({
    display: "none",
    opacity: 0,
    margin: 0,
  });
  const [heartStyle, setHeartStyle] = useState<any>({
    transform: `translate(-42vw, -8vh)`,
    transition: "transform 300ms ease-in-out",
    opacity: 0,
  });
  const [heartStrokeStyle, setHeartStrokeStyle] = useState<any>({
    transform: `translate(0, -100%)`,
    transition: "transform 300ms ease-in-out",
    opacity: 0,
  });
  const [accidentStyle, setAccidentStyle] = useState<any>({
    transform: `translate(0, -100%)`,
    transition: "transform 300ms ease-in-out",
    opacity: 0,
  });
  const [fireStyle, setFireStyle] = useState<any>({
    transform: `translate(0, -100%)`,
    transition: "transform 300ms ease-in-out",
    opacity: 0,
  });
  const [cancerStyle, setCancerStyle] = useState<any>({
    transform: `translate(0, -100%)`,
    transition: "transform 300ms ease-in-out",
    opacity: 0,
  });
  const [virusStyle, setVirusStyle] = useState<any>({
    transform: `translate(0, -100%)`,
    transition: "transform 300ms ease-in-out",
    opacity: 0,
  });

  useMotionValueEvent(scrollYProgress, "change", (latest) => {
    if (isLargerThan768) {
      if (latest > 0.7) {
        setCircleStyle((prev: any) => ({
          ...prev,
          background: "#080F16",
          borderRadius: "0",
          transform: `translate(-9%, -4%)`,
        }));
      } else if (latest > 0.6) {
        setCircleStyle((prev: any) => ({
          ...prev,
          background: "#080F16",
          height: `120vh`,
          transform: `translate(-9%, -4%)`,
        }));
      } else if (latest > 0.4) {
        setCircleStyle((prev: any) => ({
          ...prev,
          transform: `translate(-9%, ${88 - latest * 130}%)`,
          height: `110vh`,
          borderRadius: "50%",
          background: "#141024c7",
        }));
        setHeadingStyle((prev: any) => ({
          ...prev,
          display: "none",
        }));
        setSubHeadingStyle((prev: any) => ({
          ...prev,
          display: "none",
        }));
        setContentStyle((prev: any) => ({
          ...prev,
          display: "none",
        }));
      } else if (latest > 0.33) {
        setCircleStyle((prev: any) => ({
          ...prev,
          transform: `translate(-9%, ${-8 + latest * 250}%)`,
          height: `${70 + latest * 40}vh`,
          borderRadius: "50%",
        }));
      } else if (latest > 0.31) {
        setCircleStyle((prev: any) => ({
          ...prev,
          transform: `translate(-9%, ${-8 + latest * 150}%)`,
          height: `${70 + latest * 40}vh`,
          borderRadius: "50%",
        }));
        setHeadingStyle((prev: any) => ({
          ...prev,
          opacity: 1 - latest * 2,
          display: "block",
        }));
        setSubHeadingStyle((prev: any) => ({
          ...prev,
          opacity: 1 - latest * 2,
          display: "block",
        }));
        setContentStyle((prev: any) => ({
          ...prev,
          opacity: 1 - latest * 2,
          display: "block",
        }));
      } else if (latest > 0) {
        setHeadingStyle({
          fontSize: isLargerThan768
            ? `${48 + latest * 40}px`
            : `${25 + latest * 30}px`,
          transform: `translateY(${0 + latest * 50}%)`,
          opacity: 1,
        });
        setCircleStyle({
          transform: `translate(-9%, -${58 - latest * 160}%)`,
          height: `${70 + latest * 30}vh`,
          background: "#141024c7",
          borderRadius: "0 0 50% 50%",
        });
        setSubHeadingStyle({
          transform: `translateY(-${0 + latest * 1800}%)`,
          opacity: 1,
        });
        setContentStyle({
          opacity: 0 + latest * 3,
          display: latest > 0.17 ? "block" : "none",
          margin: `${latest * 50}px 0 ${latest * 90}px`,
        });
        setHeartStyle((prev: any) => ({
          ...prev,
          opacity: 1,
          transform: `translate(-42vw, -8vh)`,
        }));
        setHeartStrokeStyle((prev: any) => ({
          ...prev,
          opacity: 1,
          transform: `translate(-30vw, -2vh)`,
        }));
        setCancerStyle((prev: any) => ({
          ...prev,
          opacity: 1,
          transform: `translate(25vw, -22vh)`,
        }));
        setAccidentStyle((prev: any) => ({
          ...prev,
          opacity: 1,
          transform: `translate(44vw, -17vh)`,
        }));
        setFireStyle((prev: any) => ({
          ...prev,
          opacity: 1,
          transform: `translate(35vw, 4vh)`,
        }));
        setVirusStyle((prev: any) => ({
          ...prev,
          opacity: 1,
          transform: `translate(-25vw, -20vh)`,
        }));
      } else {
        setHeadingStyle({
          fontSize: isLargerThan768 ? "48px" : "25px",
          transform: `translateY(0%)`,
        });
        setCircleStyle({
          transform: "translate(-9%, -58%)",
          height: "70vh",
          background: "#080F16",
          borderRadius: "0 0 50% 50%",
        });
        setSubHeadingStyle({
          transform: `translateY(0%)`,
        });
        setContentStyle({
          opacity: 0,
          display: "none",
        });
        setHeartStyle((prev: any) => ({
          ...prev,
          opacity: 0,
          transform: `translate(-42vw, -50vh)`,
        }));
        setHeartStrokeStyle((prev: any) => ({
          ...prev,
          opacity: 0,
          transform: `translate(-30vw, -50vh)`,
        }));
        setCancerStyle((prev: any) => ({
          ...prev,
          opacity: 0,
          transform: `translate(25vw, -50vh)`,
        }));
        setAccidentStyle((prev: any) => ({
          ...prev,
          opacity: 0,
          transform: `translate(44vw, -50vh)`,
        }));
        setFireStyle((prev: any) => ({
          ...prev,
          opacity: 0,
          transform: `translate(38vw, -50vh)`,
        }));
        setVirusStyle((prev: any) => ({
          ...prev,
          opacity: 0,
          transform: `translate(-25vw, -50vh)`,
        }));
      }
    } else {
      if (latest > 0.7) {
        setCircleStyle((prev: any) => ({
          ...prev,
          background: "#080F16",
          borderRadius: "0",
          transform: `translate(-24%, -4%)`,
        }));
      } else if (latest > 0.6) {
        setCircleStyle((prev: any) => ({
          ...prev,
          background: "#080F16",
          height: `120vh`,
          transform: `translate(-24%, -4%)`,
        }));
      } else if (latest > 0.4) {
        setCircleStyle((prev: any) => ({
          ...prev,
          height: `110vh`,
          borderRadius: "50%",
          background: "#141024c7",
          transform: "translate(-24%, -15%)",
        }));
        setHeadingStyle((prev: any) => ({
          ...prev,
          display: "none",
        }));
        setSubHeadingStyle((prev: any) => ({
          ...prev,
          display: "none",
        }));
        setContentStyle((prev: any) => ({
          ...prev,
          display: "none",
        }));
      } else if (latest > 0.33) {
        setCircleStyle((prev: any) => ({
          ...prev,
          height: `${70 + latest * 40}vh`,
          borderRadius: "50%",
        }));
      } else if (latest > 0.31) {
        setCircleStyle((prev: any) => ({
          ...prev,
          height: `${70 + latest * 40}vh`,
          borderRadius: "50%",
        }));
        setHeadingStyle((prev: any) => ({
          ...prev,
          opacity: 1 - latest * 2,
          display: "block",
        }));
        setSubHeadingStyle((prev: any) => ({
          ...prev,
          opacity: 1 - latest * 2,
          display: "block",
        }));
        setContentStyle((prev: any) => ({
          ...prev,
          opacity: 1 - latest * 2,
          display: "block",
        }));
      } else if (latest > 0) {
        setHeadingStyle({
          fontSize: `${25 + latest * 30}px`,
          transform: `translateY(${0 + latest * 30}%)`,
          opacity: 1,
        });
        setCircleStyle({
          transform: `translate(-24%, -${50 - latest * 120}%)`,
          height: `${70 + latest * 30}vh`,
          background: "#141024c7",
          borderRadius: "0 0 50% 50%",
        });
        setSubHeadingStyle({
          transform: `translateY(-${0 + latest * 1500}%)`,
          opacity: 1,
        });
        setContentStyle({
          opacity: 0 + latest * 3,
          display: latest > 0.17 ? "block" : "none",
          margin: `${latest * 100}px 0 ${latest * 90}px`,
        });
        setHeartStyle((prev: any) => ({
          ...prev,
          opacity: 1,
          transform: `translate(0vw, 4vh)`,
        }));
        setHeartStrokeStyle((prev: any) => ({
          ...prev,
          opacity: 1,
          transform: `translate(30vw, 3vh)`,
        }));
        setCancerStyle((prev: any) => ({
          ...prev,
          opacity: 1,
          transform: `translate(-35vw, 4vh)`,
        }));
        setAccidentStyle((prev: any) => ({
          ...prev,
          opacity: 1,
          transform: `translate(33vw, -15vh)`,
        }));
        setFireStyle((prev: any) => ({
          ...prev,
          opacity: 1,
          transform: `translate(0vw, -14vh)`,
        }));
        setVirusStyle((prev: any) => ({
          ...prev,
          opacity: 1,
          transform: `translate(-35vw, -15vh)`,
        }));
      } else {
        setHeadingStyle({
          fontSize: isLargerThan768 ? "48px" : "25px",
          transform: `translateY(0%)`,
        });
        setCircleStyle({
          transform: "translate(-24%, -50%)",
          height: "70vh",
          background: "#080F16",
          borderRadius: "0 0 50% 50%",
        });
        setSubHeadingStyle({
          transform: `translateY(0%)`,
        });
        setContentStyle({
          opacity: 0,
          display: "none",
        });
        setHeartStyle((prev: any) => ({
          ...prev,
          opacity: 0,
          transform: `translate(0vw, -50vh)`,
        }));
        setHeartStrokeStyle((prev: any) => ({
          ...prev,
          opacity: 0,
          transform: `translate(30vw, -50vh)`,
        }));
        setCancerStyle((prev: any) => ({
          ...prev,
          opacity: 0,
          transform: `translate(-35vw, -50vh)`,
        }));
        setAccidentStyle((prev: any) => ({
          ...prev,
          opacity: 0,
          transform: `translate(33vw, -50vh)`,
        }));
        setFireStyle((prev: any) => ({
          ...prev,
          opacity: 0,
          transform: `translate(0vw, -50vh)`,
        }));
        setVirusStyle((prev: any) => ({
          ...prev,
          opacity: 0,
          transform: `translate(-35vw, -50vh)`,
        }));
      }
    }
  });

  return (
    <Center
      width={{ base: "200%", md: "120%" }}
      sx={{
        alignItems:
          scrollYProgress.get() > 0.6
            ? "center"
            : scrollYProgress.get() > 0.33
            ? isLargerThan768
              ? "flex-start"
              : "flex-end"
            : "flex-end",
        display: "flex",
        ...circleStyle,
      }}
      position="fixed"
      zIndex={2}
    >
      <AnimatePresence>
        {scrollYProgress.get() > 0.9 ? (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{
              duration: 0.8,
              delay: 0.1,
              ease: [0, 0.71, 0.2, 1.01],
            }}
          >
            <Stack
              color="#D8DBF1"
              spacing={10}
              width={{ base: "98vw", md: "80vw" }}
              alignItems="center"
              textAlign={{ base: "center", md: "left" }}
            >
              <AboutUsBgGlow
                width={{ md: "1175px" }}
                height={{ md: "456px" }}
                position="absolute"
                transform="translateY(10%)"
              />
              <motion.div
                initial={{ y: -1000 }}
                animate={{ y: 0 }}
                transition={{ ease: "easeInOut", duration: 0.5 }}
                exit={{ y: -1000 }}
              >
                <Text fontSize="28px" mb="10%">
                  Securely Yours:{" "}
                  <Text as="span" color="#995EF9">
                    WALLT
                  </Text>{" "}
                  - Your Private Vault in the Cloud
                </Text>
                <Stack spacing={10}>
                  <Text>
                    WALLT is a SaaS solution that provides you with a secure
                    cloud storage, tailored to help you organize your documents
                    and information, and gives you the flexibility to choose
                    when and with whom you want to share this information.
                  </Text>
                  <Text>
                    It is end-to-end encrypted to maintain the confidentiality
                    and privacy of your information. This means that not even we
                    can access information you put up on WALLT. We use various
                    ingenious security measures to keep you protected, always,
                    and provide services through robust processes that ensure we
                    deliver 100% of the time.
                  </Text>
                </Stack>
              </motion.div>
              <motion.div
                initial={{ opacity: 0, y: 1000 }}
                animate={{ opacity: 1, y: 1 }}
                transition={{ ease: "easeInOut", duration: 0.5 }}
              >
                <Dashboard
                  width={isLargerThan768 ? "80vw" : "90vw"}
                  height="100%"
                />
              </motion.div>
            </Stack>
          </motion.div>
        ) : scrollYProgress.get() > 0.8 ? (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{
              duration: 0.8,
              delay: 0.1,
              ease: [0, 0.71, 0.2, 1.01],
            }}
          >
            <Stack
              color="#D8DBF1"
              spacing={{ base: 5, md: 10 }}
              width={{ base: "98vw", md: "80vw" }}
              alignItems="center"
            >
              <Text
                fontSize={{ base: "22px", md: 28 }}
                fontWeight="bold"
                textAlign="center"
              >
                This problem is so universal for most of the people we spoke to,
                and it required a solution. 
              </Text>
              <AboutUsBgGlow
                width={{ md: "1175px" }}
                height={{ md: "456px" }}
                position="absolute"
                transform={{ base: "translateY(60%)", md: "translateY(10%)" }}
              />
              <motion.div
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{
                  duration: 0.8,
                  delay: 0.2,
                  ease: [0, 0.71, 0.2, 1.01],
                }}
              >
                <WalltLogo fontSize={{ base: "65px", md: "133px" }} />
              </motion.div>
              <Stack
                spacing={{ base: 8, md: 10 }}
                textAlign={{ base: "center", md: "left" }}
              >
                <Text>
                  The solution had to be easy, convenient, trustworthy,
                  confidential, secure, and foolproof. Information relating to
                  personal finances, insurance, and legal documents could only
                  be transferred reliably if it minimized human intervention.
                </Text>
                <Text>
                  It would require a secure and reliable service provider to
                  share the information impartially and provide these services
                  for the entire lifespan. The infrastructure had to be robust
                  enough to withstand challenges like a pandemic without
                  faltering.
                </Text>
                <Text>
                  Discussions amongst the founders led us to devise a model
                  based on end-to-end encrypted cloud storage, creating a
                  resilient service for keeping your affairs in order{" "}
                  <Text as="span" color="#1BEBE7">
                    —a digital vault: ‘WALLT’.
                  </Text>
                </Text>
                <Text>
                  With industry-leading safety standards, end-to-end encryption,
                  and robust processes regarding your data, WALLT offers a
                  secure digital vault that allows you to undertake end-of-life
                  planning holistically.
                </Text>
              </Stack>
            </Stack>
          </motion.div>
        ) : scrollYProgress.get() > 0.7 ? (
          <Stack
            color="#D8DBF1"
            spacing="70px"
            width={{ base: "98vw", md: "80vw" }}
          >
            <motion.div
              style={{ position: "absolute" }}
              initial={{ x: -1000 }}
              animate={
                isLargerThan768
                  ? { x: "-25%", y: "40%" }
                  : { x: "-25%", y: "125%" }
              }
            >
              <Covid
                fontSize={{ base: "137px", md: "386px" }}
                marginLeft={10}
              />
            </motion.div>
            <motion.div
              style={{ position: "absolute" }}
              initial={{ x: "1000%" }}
              animate={
                isLargerThan768 ? { x: "660%", y: 0 } : { x: "280%", y: "135%" }
              }
            >
              <Covid fontSize={{ base: "51px", md: "111px" }} marginLeft={10} />
            </motion.div>
            <Text
              fontSize={{ base: "25px", md: "36px" }}
              textAlign="center"
              fontWeight="semibold"
            >
              What could be the solution to battle such challenges? 
            </Text>
            <HStack
              flexDirection={{ base: "column", md: "row" }}
              spacing={8}
              alignItems="flex-start"
              textAlign={{ base: "center", md: "left" }}
            >
              <Stack spacing={6} fontSize={{ base: "18px", md: "24px" }}>
                <Text>
                  WALLT emerged from the collective experience we endured during
                  the COVID-19 pandemic.
                </Text>
                <Text>
                  The idea germinated when one of us lost their father during
                  this time. 
                </Text>
              </Stack>
              <Stack spacing={10} color="#CDCAD7">
                <Text>
                  While the family was still grieving, they were advised to
                  inform the Insurer of the unfortunate demise immediately. Then
                  started the anxiety around documents. The family was not aware
                  of the policy details and the process of how to claim it.
                </Text>
                <Text>
                  They also realized that they were not aware of how many bank
                  accounts he had, his PF account, whether he had left a will,
                  and even where the property documents of their house were
                  stored.
                </Text>
                <Text>
                  The family found hard copies of Fixed Deposits under a mat in
                  the closet six months after his demise; and that there was a
                  locker in his name - when the bank sought renewal of the
                  locker security amount.
                </Text>
                <motion.div
                  style={{ position: isLargerThan768 ? "static" : "absolute" }}
                  initial={{ y: "100%" }}
                  animate={
                    isLargerThan768 ? { y: "5%" } : { x: "220%", y: "155%" }
                  }
                >
                  <Covid
                    fontSize={{ base: "70px", md: "76px" }}
                    marginLeft={10}
                  />
                </motion.div>
              </Stack>
            </HStack>
          </Stack>
        ) : scrollYProgress.get() > 0.6 ? (
          <Stack
            spacing={7}
            width={{ base: "98vw", md: "80vw" }}
            fontSize={{ base: "16px", md: "24px" }}
            color="#D8DBF1"
            alignItems="center"
            textAlign="center"
          >
            <motion.div
              style={{ position: "absolute" }}
              initial={{ x: "-100vw", y: "-100vh" }}
              animate={
                isLargerThan768
                  ? { x: "-40vw", y: "-10vh" }
                  : { x: "-38vw", y: "-12vh" }
              }
              transition={{ ease: "easeInOut" }}
              exit={{ x: "-100vw", y: "-100vh" }}
            >
              <Court
                width={{ base: "64px", md: "68px" }}
                height={{ base: "56px", md: "60px" }}
                opacity={0.3}
              />
            </motion.div>
            {isLargerThan768 && (
              <motion.div
                style={{ position: "absolute" }}
                initial={{ x: "-20vw", y: "-100vh" }}
                animate={{ x: "-20vw", y: "-18vh" }}
                transition={{ ease: "easeInOut" }}
                exit={{ x: "-20vw", y: "-100vh" }}
              >
                <GraphIcon width="70px" height="66px" opacity={0.3} />
              </motion.div>
            )}
            <motion.div
              style={{ position: "absolute" }}
              initial={{ x: "2vw", y: "-100vh" }}
              animate={
                isLargerThan768
                  ? { x: "2vw", y: "-18vh" }
                  : { x: "0vw", y: "-18vh" }
              }
              transition={{ ease: "easeInOut" }}
            >
              <KeyIcon
                width={{ base: "47px", md: "70px" }}
                height={{ base: "67px", md: "100px" }}
                opacity={0.3}
              />
            </motion.div>
            {isLargerThan768 && (
              <motion.div
                style={{ position: "absolute" }}
                initial={{ x: "22vw", y: "-100vh" }}
                animate={{ x: "22vw", y: "-12vh" }}
                transition={{ ease: "easeInOut" }}
              >
                <Court
                  width={{ md: "68px" }}
                  height={{ md: "60px" }}
                  opacity={0.3}
                />
              </motion.div>
            )}
            <motion.div
              style={{ position: "absolute" }}
              initial={{ x: "100vw", y: "-100vh" }}
              animate={
                isLargerThan768
                  ? { x: "42vw", y: "-15vh" }
                  : { x: "36vw", y: "-11vh" }
              }
              transition={{ ease: "easeInOut" }}
            >
              <HomeIcon
                width={{ base: "54px", md: "68px" }}
                height={{ base: "46.6px", md: "60px" }}
                opacity={0.3}
              />
            </motion.div>
            <Text lineHeight={2}>
              Deep down, we often delude ourselves into thinking that life will
              be kind and accommodating enough to afford us the luxury of time{" "}
              <strong>to get our affairs in order</strong>.  
            </Text>
            <Text lineHeight={2.5}>
              In India, there's{" "}
              <Text as="strong" color="#1BEBE7">
                over INR 82,000 Crores
              </Text>{" "}
              lying unclaimed in bank accounts, insurance companies, provident
              funds, mutual funds, stocks, matured fixed deposits, and
              dividends.
            </Text>
            <motion.div
              style={{ position: "absolute" }}
              initial={{ x: "-100vw", y: "100vh" }}
              animate={
                isLargerThan768
                  ? { x: "-40vw", y: "27vh" }
                  : { x: "-36vw", y: "40vh" }
              }
              transition={{ ease: "easeInOut" }}
            >
              <DocumentIcon
                width={{ base: "70px", md: "68px" }}
                height={{ base: "63px", md: "62px" }}
                opacity={0.3}
              />
            </motion.div>
            <motion.div
              style={{ position: "absolute" }}
              initial={{ x: "-10vw", y: "100vh" }}
              animate={
                isLargerThan768
                  ? { x: "-16vw", y: "40vh" }
                  : { x: "0vw", y: "50vh" }
              }
              transition={{ ease: "easeInOut" }}
            >
              <HomeIcon
                width={{ base: "60px", md: "88px" }}
                height={{ base: "52px", md: "76px" }}
                opacity={0.3}
              />
            </motion.div>
            <motion.div
              style={{ position: "absolute" }}
              initial={{ x: "15vw", y: "100vh" }}
              animate={
                isLargerThan768
                  ? { x: "15vw", y: "35vh" }
                  : { x: "35vw", y: "39vh" }
              }
              transition={{ ease: "easeInOut" }}
            >
              <GraphIcon
                width={{ base: "65px", md: "104px" }}
                height={{ base: "61px", md: "98px" }}
                opacity={0.3}
              />
            </motion.div>
            {isLargerThan768 && (
              <motion.div
                style={{ position: "absolute" }}
                initial={{ x: "15vw", y: "100vh" }}
                animate={{ x: "38vw", y: "30vh" }}
                transition={{ ease: "easeInOut" }}
              >
                <DocumentIcon width="106px" height="96px" opacity={0.3} />
              </motion.div>
            )}
          </Stack>
        ) : scrollYProgress.get() > 0.33 ? (
          <Stack
            spacing={2}
            width={{ base: "98vw", md: "70vw" }}
            mt={{ base: "10%", md: "5%" }}
            mb={{ base: "15%", md: 0 }}
            color="#D8DBF1"
            textAlign="center"
          >
            <Text fontSize={{ md: "20px" }}>
              Many families, ours included, grappled with locating details of{" "}
              <strong>
                bank accounts, pension documents, locker details, title deeds,
                and most crucially, Wills.
              </strong>{" "}
              While hindsight offers clarity, foresight is invaluable. This
              realization sparked our imagination and got us thinking..
            </Text>
          </Stack>
        ) : (
          <Stack
            spacing={2}
            color="#D8DBF1"
            mb={{ base: "10%", md: "3%" }}
            alignItems="center"
            width={{ base: "98vw", md: "80vw" }}
          >
            <Text sx={headingStyle}>
              Story behind{" "}
              <Text as="span" color="#1BEBE7">
                WALLT
              </Text>
            </Text>
            <Text
              sx={subHeadingStyle}
              textAlign="center"
              fontSize={{ base: "14px", md: "16px" }}
            >
              Get your affairs in order (and{" "}
              <strong>do it before it is too late!</strong>)
            </Text>
            <Text
              style={contentStyle}
              width={{ base: "90vw", md: "70vw" }}
              sx={{ textAlign: "center" }}
              fontSize={{ base: "16px", md: "20px" }}
            >
              <strong>Losing a loved one</strong> is deeply traumatic, and so is
              the anxiety over financial security that follows such a loss.
              Instead of grieving, people find themselves caught up in the
              practicalities of{" "}
              <strong>managing an individual’s financial legacy.</strong>
            </Text>
            <HeartIcon
              width={{ base: "67px", md: "81px" }}
              height={{ base: "86px", md: "104px" }}
              position="absolute"
              sx={{ ...heartStyle }}
            />
            <HeartStroke
              width={{ base: "56px", md: "95px" }}
              height={{ base: "63px", md: "108px" }}
              position="absolute"
              sx={{ ...heartStrokeStyle }}
            />
            <CancerIcon
              width={{ base: "34px", md: "78px" }}
              height={{ base: "53px", md: "122px" }}
              position="absolute"
              sx={{ ...cancerStyle }}
            />
            <AccidentIcon
              width={{ base: "62px", md: "120px" }}
              height={{ base: "61px", md: "119px" }}
              position="absolute"
              sx={{ ...accidentStyle }}
            />
            <FireIcon
              width={{ base: "59px", md: "89px" }}
              height={{ base: "54px", md: "82px" }}
              position="absolute"
              sx={{ ...fireStyle }}
            />
            <VirusIcon
              width={{ base: "69px", md: "105px" }}
              height={{ base: "66px", md: "100px" }}
              position="absolute"
              sx={{ ...virusStyle }}
            />
          </Stack>
        )}
      </AnimatePresence>
    </Center>
  );
};

const Story = () => {
  const storyFoldRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: storyFoldRef,
  });
  const [storyPastStyle, setStoryPastStyle] = useState({
    display: "block",
  });

  const [storyPresentStyle, setStoryPresentStyle] = useState({
    display: "none",
  });
  const [storyFeatureStyle, setStoryFeatureStyle] = useState({
    display: "none",
  });

  useMotionValueEvent(scrollYProgress, "change", (latest) => {
    if (latest > 0.6) {
      setStoryPastStyle((prev: any) => ({
        ...prev,
        display: "none",
      }));
      setStoryPresentStyle((prev: any) => ({
        ...prev,
        display: "none",
      }));
      setStoryFeatureStyle((prev: any) => ({
        ...prev,
        display: "none",
      }));
    } else if (latest > 0.33) {
      setStoryPastStyle((prev: any) => ({
        ...prev,
        display: "none",
      }));
      setStoryPresentStyle((prev: any) => ({
        ...prev,
        display: "none",
      }));
      setStoryFeatureStyle((prev: any) => ({
        ...prev,
        display: "block",
      }));
    } else if (latest > 0.16) {
      setStoryPastStyle((prev: any) => ({
        ...prev,
        display: "none",
      }));
      setStoryPresentStyle((prev: any) => ({
        ...prev,
        display: "block",
      }));
      setStoryFeatureStyle((prev: any) => ({
        ...prev,
        display: "none",
      }));
    } else {
      setStoryPastStyle((prev: any) => ({
        ...prev,
        display: "block",
      }));
      setStoryPresentStyle((prev: any) => ({
        ...prev,
        display: "none",
      }));
      setStoryFeatureStyle((prev: any) => ({
        ...prev,
        display: "none",
      }));
    }
  });

  return (
    <Box ref={storyFoldRef} sx={{ position: "relative", overflow: "hidden" }}>
      <Box
        backgroundColor="#3F4470"
        backgroundImage="url('images/BG.png')"
        height="100vh"
        backgroundSize="cover"
        backgroundRepeat="no-repeat"
        position="fixed"
        width="100vw"
        top={0}
      />
      <CircleComponent scrollYProgress={scrollYProgress} />
      <Box height="100vh">
        <StoryPast
          width="100%"
          height="84%"
          position="fixed"
          top={{ base: "40%", md: "30%" }}
          zIndex={1}
          sx={storyPastStyle}
        />
        <StoryPresent
          width="100%"
          height="86%"
          position="fixed"
          top={{ base: "40%", md: "30%" }}
          zIndex={1}
          sx={storyPresentStyle}
        />
        <StoryFeature
          width="100%"
          height="111%"
          position="fixed"
          top={{ base: "20%", md: "10%" }}
          zIndex={1}
          sx={storyFeatureStyle}
        />
      </Box>
      <Box height="600vh"></Box>
    </Box>
  );
};

export default Story;
