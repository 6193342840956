import React from "react";

import "./App.css";

import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Layout from "./components/layout";
import Home from "./Pages/home";
import { ChakraProvider } from "@chakra-ui/react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Feature from "./Pages/feature";
import Privacy from "./Pages/privacy";
import Terms from "./Pages/terms";
import About from "./Pages/about";
import ContactUs from "./Pages/contact";

export default function App() {
  return (
    <ChakraProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="/feature" element={<Feature />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact-us" element={<ContactUs />} />
          </Route>
        </Routes>
      </Router>
    </ChakraProvider>
  );
}
