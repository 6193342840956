import { createIcon } from "@chakra-ui/icons";

const AboutUsBgGlow = createIcon({
  displayName: "AboutUsBgGlow",
  viewBox: "0 0 1175 456",
  path: (
    <>
      <g opacity=".3" filter="url(#about-us-bg-glow-a)">
        <path
          d="M144.037 295.625V312c-1.976-1.975 75.138 0 75.138 0h736.688s73.557-1.58 75.137 0v-16.375c0-29.515-28.67-56.768-75.137-71.537L662.677 131.06c-46.514-14.747-103.803-14.747-150.317 0l-293.185 93.028c-46.514 14.746-75.138 42.022-75.138 71.537Z"
          fill="#7623FF"
        />
      </g>
      <defs>
        <filter
          id="about-us-bg-glow-a"
          x="0"
          y="-24"
          width="1175"
          height="480"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur
            stdDeviation="72"
            result="effect1_foregroundBlur_767_40110"
          />
        </filter>
      </defs>
    </>
  ),
});

export default AboutUsBgGlow;
