import { Box, Heading, Stack, Text } from "@chakra-ui/react";
import {
  motion,
  MotionValue,
  useMotionValueEvent,
  useScroll,
  useTransform,
} from "framer-motion";
import { useRef, useState } from "react";
import HomeHexa1 from "../../icons/HomeHexa1";
import HomeHexa2 from "../../icons/HomeHexa2";
import Ellipsis from "../../icons/Ellipsis";
import EllipsisMob from "../../icons/ElipsisMob";
import Vault from "../../icons/Vault";
import VaultShadow from "../../icons/VaultShadow";
import Court from "../../icons/Court";
import HomeIcon from "../../icons/HomeIcon";
import MaleAvatarBlue from "../../icons/MaleAvatarBlue";
import KeyIcon from "../../icons/KeyIcon";
import GraphIcon from "../../icons/GraphIcon";
import DocumentIcon from "../../icons/Document";
import FemaleAvatar from "../../icons/FemalAvatar";
import MaleAvatarPurple from "../../icons/MaleAvatarPurple";
import { useResponsive } from "../../providers/useMediaProvider";

export function useParallax(
  value: MotionValue<number>,
  distance: number,
  distance2: number
) {
  return useTransform(value, [1, 0], [`${distance2}%`, `${distance}%`]);
}

const Hexa1Component = ({
  scrollYProgress,
}: {
  scrollYProgress: MotionValue<number>;
}) => {
  const { isLargerThan768 } = useResponsive();

  const y = useParallax(
    scrollYProgress,
    isLargerThan768 ? 20 : 25,
    isLargerThan768 ? 120 : 360
  );

  return (
    <motion.div
      style={{
        y,
        translateX: isLargerThan768 ? "65vw" : "34vw",
        width: "fit-content",
        position: "absolute",
      }}
    >
      <HomeHexa1
        width={{ base: "64vw", md: "23vw" }}
        height={{ base: "66vw", md: "25vw" }}
        fill="none"
      />
    </motion.div>
  );
};

const Hexa2Component = ({
  scrollYProgress,
}: {
  scrollYProgress: MotionValue<number>;
}) => {
  const { isLargerThan768 } = useResponsive();

  const [hexa1Style, setHexa1Style] = useState({
    transform:
      window.innerWidth > 800 ? "translate(34%, 80%)" : "translate(6%, 18%)",
  });

  useMotionValueEvent(scrollYProgress, "change", (latest) => {
    if (isLargerThan768) {
      if (latest > 0.5) {
        setHexa1Style({
          transform: `translate(75%, ${88 + 270 * latest}%)`,
        });
      } else {
        setHexa1Style({
          transform: `translate(${34 + 60 * latest}%, ${80 + 270 * latest}%)`,
        });
      }
    } else {
      if (latest > 0.5) {
        setHexa1Style({
          transform: `translate(32%, ${450 + 870 * latest}%)`,
        });
      } else {
        setHexa1Style({
          transform: `translate(20%, ${280 + 1070 * latest}%)`,
        });
      }
    }
  });

  return (
    <motion.div
      style={{
        ...hexa1Style,
        width: "fit-content",
        position: "absolute",
      }}
    >
      <HomeHexa2
        width={{ base: "30vw", md: "20vw" }}
        height={{ base: "30vw", md: "20vw" }}
        fill="none"
        opacity={0.4}
      />
    </motion.div>
  );
};

const EllipsisFold = ({
  scrollYProgress,
}: {
  scrollYProgress: MotionValue<number>;
}) => {
  const { isLargerThan768 } = useResponsive();

  const [ellipsisWidth, setEllipsisWidth] = useState<any>({
    width: window.innerWidth > 800 ? "80vw" : "90vw",
    height: window.innerWidth > 800 ? 315 : 69,
    margin: window.innerWidth > 800 ? 245 : 60,
  });

  useMotionValueEvent(scrollYProgress, "change", (latest) => {
    if (isLargerThan768) {
      if (latest > 0.5) {
        setEllipsisWidth({ width: "75vw", height: 79, margin: 70 });
      } else {
        setEllipsisWidth({
          width: `${80 - 15 * latest}vw`,
          height: 300 - 50 * latest,
          margin: 230 - 50 * latest,
        });
      }
    } else {
      if (latest > 0.5) {
        setEllipsisWidth({
          width: "75vw",
          height: 79,
          margin: 150,
          opacity: 0,
        });
      } else {
        setEllipsisWidth({
          width: `${90 - 15 * latest}vw`,
          height: 69 - 50 * latest,
          margin: 60 - 10 * latest,
          opacity: 1 - 60 * latest,
        });
      }
    }
  });

  const y = useParallax(
    scrollYProgress,
    isLargerThan768 ? 70 : 330,
    isLargerThan768 ? 360 : 900
  );

  return (
    <motion.div
      style={{
        position: "absolute",
        y,
        width: "100%",
      }}
    >
      {isLargerThan768 ? (
        <Stack alignItems="center">
          <Ellipsis
            width={ellipsisWidth.width}
            height={ellipsisWidth.height}
            fill="none"
          />
          <Ellipsis
            width={ellipsisWidth.width}
            height={ellipsisWidth.height}
            fill="none"
            marginTop={`-${ellipsisWidth.margin}px`}
          />
          <Ellipsis
            width={ellipsisWidth.width}
            height={ellipsisWidth.height}
            fill="none"
            marginTop={`-${ellipsisWidth.margin}px`}
          />
        </Stack>
      ) : (
        <Stack alignItems="center">
          <EllipsisMob
            width={ellipsisWidth.width}
            height={ellipsisWidth.height}
            opacity={ellipsisWidth.opacity}
            fill="none"
          />
          <EllipsisMob
            width={ellipsisWidth.width}
            height={ellipsisWidth.height}
            fill="none"
            marginTop={`-${ellipsisWidth.margin}px`}
            opacity={ellipsisWidth.opacity}
          />
          <EllipsisMob
            width={ellipsisWidth.width}
            height={ellipsisWidth.height}
            fill="none"
            marginTop={`-${ellipsisWidth.margin}px`}
            opacity={ellipsisWidth.opacity}
          />
        </Stack>
      )}
    </motion.div>
  );
};

const ContentOne = () => {
  const contentRef = useRef(null);
  const { isLargerThan768 } = useResponsive();

  const [headlineStyle, setHeadlineStyle] = useState({
    spacing: window.innerWidth > 800 ? "5vh" : "37vh",
    fontSize: window.innerWidth > 800 ? "60px" : "25px",
    transition: "spacing 330ms ease-in-out, font-size 330ms ease-in-out",
  });
  const [shadowOpacity, setShadowOpacity] = useState<number>(1);

  const { scrollYProgress } = useScroll({
    target: contentRef,
  });

  const y = useParallax(
    scrollYProgress,
    isLargerThan768 ? 28 : 32,
    isLargerThan768 ? 100 : 120
  );

  const vaultY = useParallax(
    scrollYProgress,
    isLargerThan768 ? 30 : -130,
    isLargerThan768 ? 300 : 600
  );
  const vaultDisplay = useTransform(scrollYProgress, [1, 0], [0, 1]);

  const vaultShadowY = useParallax(
    scrollYProgress,
    isLargerThan768 ? 90 : -90,
    100
  );

  useMotionValueEvent(scrollYProgress, "change", (latest) => {
    if (isLargerThan768) {
      if (latest > 0.5) {
        setHeadlineStyle((prev) => ({
          ...prev,
          spacing: "20vh",
          fontSize: "80px",
        }));
        setShadowOpacity(0);
      } else {
        setHeadlineStyle((prev) => ({
          ...prev,
          spacing: `${5 + 20 * latest}vh`,
          fontSize: `${60 + 25 * latest}px`,
        }));
        setShadowOpacity(1 - 1.5 * latest);
      }
    } else {
      if (latest > 0.5) {
        setHeadlineStyle((prev) => ({
          ...prev,
          spacing: "10vh",
          fontSize: "30px",
        }));
        setShadowOpacity(0);
      } else {
        setHeadlineStyle((prev) => ({
          ...prev,
          spacing: `${40 - 30 * latest}vh`,
          fontSize: `${25 + 15 * latest}px`,
        }));
        setShadowOpacity(1 - 1.5 * latest);
      }
    }
  });

  return (
    <Box ref={contentRef} height="120vh">
      <motion.div style={{ y }}>
        <Stack spacing={headlineStyle.spacing}>
          <Heading
            as="h1"
            sx={{ fontWeight: "bold" }}
            fontSize={headlineStyle.fontSize}
            textAlign="center"
            color="white"
          >
            A digital custodian to facilitate <br />
            <Text as="span" color="#1BEBE7">
              end-of-life planning
            </Text>
          </Heading>
          <Heading
            as="h4"
            fontSize={{ base: "16px", md: "18px" }}
            textAlign="center"
            color="#CDCAD7"
          >
            Safeguard your legacy with a robust tool that secures, stores, and
            transmits all your confidential personal and <br /> financial
            information through an end-to-end encrypted digital vault.
          </Heading>
        </Stack>
      </motion.div>
      <motion.div
        style={{ y: vaultShadowY, position: "absolute", width: "100%" }}
      >
        <Stack alignItems="center">
          <VaultShadow
            width={{ base: 256, md: 989 }}
            height={{ base: 99, md: 383 }}
            fill="none"
            opacity={shadowOpacity}
          />
        </Stack>
      </motion.div>
      <Box as={motion.div} style={{ y: vaultY }}>
        <Stack alignItems="center">
          <Vault
            width={{ base: 105, md: 317 }}
            height={{ base: 150, md: 433 }}
            fill="none"
            opacity={vaultDisplay.get()}
          />
        </Stack>
      </Box>
    </Box>
  );
};

const ContentTwo = () => {
  const { isLargerThan768 } = useResponsive();
  const { scrollYProgress } = useScroll();

  const y = useParallax(scrollYProgress, isLargerThan768 ? 50 : 10, 300);

  return (
    <motion.div style={{ y }}>
      <Stack
        gap={{ base: 8, md: 20 }}
        width={{ base: "95vw", md: "80vw" }}
        margin="auto"
        align="center"
        paddingX="10px"
      >
        <Heading
          as="h2"
          width={{ base: "98vw", md: "80vw" }}
          fontSize={{ base: "25px", md: "46px" }}
          textAlign="center"
          color="white"
        >
          We take care of your{" "}
          <Text as="span" color="#1BEBE7">
            loved ones
          </Text>
        </Heading>
        <Stack
          width={{ base: "90vw", md: "60vw" }}
          lineHeight={2.5}
          spacing={{ base: 10, md: 20 }}
        >
          <Heading
            as="h4"
            fontSize={{ base: "16px", md: "18px" }}
            textAlign="center"
            color="#CDCAD7"
          >
            Ever worried about what your loved ones would go through if
            something happened to you? 
            <br />
            At WALLT, we've got you covered. 
          </Heading>
          <Heading
            as="h4"
            fontSize={{ base: "16px", md: "18px" }}
            textAlign="center"
            color="#CDCAD7"
          >
            WALLT helps you protect your loved ones by letting you organize and
            pass on all relevant information to the intended recipients in a
            safe, secure, and foolproof way.
          </Heading>
        </Stack>
      </Stack>
    </motion.div>
  );
};

const IntroFold = () => {
  const { isLargerThan768 } = useResponsive();
  const ref = useRef(null);

  const [homeIconWidth, setHomeIconWidth] = useState<number>(118);
  const [documentIconWidth, setDocumentIconWidth] = useState<number>(133);
  const [chartIconWidth, setChartIconWidth] = useState<number>(127);
  const [keyIconWidth, setKeyIconWidth] = useState<number>(77);
  const [avatarWidthHeight, setAvatarWidthHeight] = useState<number>(
    window.innerWidth > 800 ? 75 : 7
  );
  const [courtIconStyle, setCourtIconStyle] = useState({
    transform:
      window.innerWidth > 800 ? "translate(15%, 20%)" : "translate(7%, 19%)",
    opacity: 1,
    transition: "transform 330ms ease-in-out",
  });
  const [homeIconStyle, setHomeIconStyle] = useState({
    transform:
      window.innerWidth > 800 ? "translate(81%, 29%)" : "translate(81%, 22%)",
    opacity: 1,
    transition: "transform 330ms ease-in-out",
  });
  const [maleAvatarBlueStyle, setMaleAvatarBlueStyle] = useState({
    transform:
      window.innerWidth > 800 ? "translate(27%, 19%)" : "translate(23%, 18%)",
    opacity: 1,
    transition: "transform 330ms ease-in-out",
  });
  const [maleAvatarPurpleStyle, setMaleAvatarPurpleStyle] = useState({
    transform:
      window.innerWidth > 800 ? "translate(20%, 30%)" : "translate(12%, 22%)",
    opacity: 1,
    transition: "transform 330ms ease-in-out",
  });
  const [femaleAvatarStyle, setFemaleAvatarStyle] = useState({
    transform:
      window.innerWidth > 800 ? "translate(69%, 23%)" : "translate(69%, 20.5%)",
    opacity: 1,
    transition: "transform 330ms ease-in-out",
  });
  const [documentIconStyle, setDocumentIconStyle] = useState({
    transform:
      window.innerWidth > 800 ? "translate(25%, 40%)" : "translate(21%, 24%)",
    opacity: 1,
    transition: "transform 330ms ease-in-out",
  });
  const [keyIconStyle, setKeyIconStyle] = useState({
    transform:
      window.innerWidth > 800 ? "translate(70%, 40%)" : "translate(73%, 24%)",
    opacity: 1,
    transition: "transform 330ms ease-in-out",
  });
  const [chartIconStyle, setChartIconStyle] = useState({
    transform:
      window.innerWidth > 800 ? "translate(77%, 19%)" : "translate(80%, 18.5%)",
    opacity: 1,
    transition: "transform 330ms ease-in-out",
  });

  const { scrollYProgress } = useScroll({
    target: ref,
  });

  useMotionValueEvent(scrollYProgress, "change", (latest) => {
    if (isLargerThan768) {
      if (latest > 0.4) {
        setHomeIconStyle((prev) => ({
          ...prev,
          transform: `translate(${88 - 1 * latest}%,${45 + 27 * latest}% )`,
          opacity: 0.3,
        }));
        setCourtIconStyle((prev: any) => ({
          ...prev,
          transform: `translate(${1.3 + 5 * latest}%,${17 + 65 * latest}% )`,
          opacity: 0.3,
        }));
        setMaleAvatarBlueStyle((prev: any) => ({
          ...prev,
          transform: `translate(${31 - 25 * latest}%,${35 + 35 * latest}% )`,
          opacity: 0.3,
        }));
        setMaleAvatarPurpleStyle((prev: any) => ({
          ...prev,
          transform: `translate(${27 + 12 * latest}%,${55 - latest}% )`,
          opacity: 0.3,
        }));
        setFemaleAvatarStyle((prev: any) => ({
          ...prev,
          transform: `translate(${62.2 + 20 * latest}%, ${39 + 37 * latest}%)`,
          opacity: 0.3,
        }));
        setDocumentIconStyle((prev: any) => ({
          ...prev,
          transform: `translate(${8 + 4.3 * latest}%, ${44 + 38 * latest}% )`,
          opacity: 0.3,
        }));
        setKeyIconStyle((prev: any) => ({
          ...prev,
          transform: `translate(${74 + 9 * latest}%, ${50 + 22 * latest}%)`,
          opacity: 0.3,
        }));
        setChartIconStyle((prev: any) => ({
          ...prev,
          transform: `translate(${77 - 25 * latest}%, ${15 + 42 * latest}%)`,
          opacity: 0.3,
        }));
        setHomeIconWidth(118 - 20 * latest);
        setDocumentIconWidth(133 - 50 * latest);
        setKeyIconWidth(77 - 15 * latest);
        setChartIconWidth(127 - 50 * latest);
        setAvatarWidthHeight(75 + 150 * latest);
      } else {
        setHomeIconStyle((prev: any) => ({
          ...prev,
          transform: `translate(${81 + 20 * latest}%,${29 + 50 * latest}% )`,
          opacity: 1 - 2 * latest,
        }));
        setCourtIconStyle((prev: any) => ({
          ...prev,
          transform: `translate(${15 - 40 * latest}%,${20 - 10 * latest}% )`,
          opacity: 1 - 2 * latest,
        }));
        setMaleAvatarBlueStyle((prev: any) => ({
          ...prev,
          transform: `translate(${27 + 10 * latest}%,${19 + 50 * latest}% )`,
          opacity: 1 - 2 * latest,
        }));
        setMaleAvatarPurpleStyle((prev: any) => ({
          ...prev,
          transform: `translate(${20 + 20 * latest}%,${30 + 80 * latest}% )`,
          opacity: 1 - 2 * latest,
        }));
        setFemaleAvatarStyle((prev: any) => ({
          ...prev,
          transform: `translate(${69 - 20 * latest}%, ${23 + 50 * latest}%)`,
          opacity: 1 - 2 * latest,
        }));
        setDocumentIconStyle((prev: any) => ({
          ...prev,
          transform: `translate(${25 - 50 * latest}%, ${40 + 10 * latest}% )`,
          opacity: 1 - 2 * latest,
        }));
        setKeyIconStyle((prev: any) => ({
          ...prev,
          transform: `translate(${70 + 10 * latest}%, ${40 + 30 * latest}%)`,
          opacity: 1 - 2 * latest,
        }));
        setChartIconStyle((prev: any) => ({
          ...prev,
          transform: `translate(77%, ${19 - 10 * latest}%)`,
          opacity: 1 - 2 * latest,
        }));
        setHomeIconWidth(118);
        setDocumentIconWidth(133);
        setChartIconWidth(127);
        setKeyIconWidth(77);
        setAvatarWidthHeight(75);
      }
    } else {
      if (latest > 0.4) {
        setHomeIconStyle((prev) => ({
          ...prev,
          transform: `translate(75%,${45 + 27 * latest}% )`,
          opacity: 0.3,
        }));
        setCourtIconStyle((prev: any) => ({
          ...prev,
          transform: `translate(4%,${17 + 65 * latest}% )`,
          opacity: 0.3,
        }));
        setMaleAvatarBlueStyle((prev: any) => ({
          ...prev,
          transform: `translate(34%,${35 + 35 * latest}% )`,
          opacity: 0.3,
        }));
        setMaleAvatarPurpleStyle((prev: any) => ({
          ...prev,
          transform: `translate(4%,${55 - latest}% )`,
          opacity: 0.3,
        }));
        setFemaleAvatarStyle((prev: any) => ({
          ...prev,
          transform: `translate(82%, ${39 + 37 * latest}%)`,
          opacity: 0.3,
        }));
        setDocumentIconStyle((prev: any) => ({
          ...prev,
          transform: `translate(9%, ${44 + 38 * latest}% )`,
          opacity: 0.3,
        }));
        setKeyIconStyle((prev: any) => ({
          ...prev,
          transform: `translate(65%, ${50 + 22 * latest}%)`,
          opacity: 0.3,
        }));
        setChartIconStyle((prev: any) => ({
          ...prev,
          transform: `translate(70%, ${18.5 + 42 * latest}%)`,
          opacity: 0.3,
        }));
      } else {
        setHomeIconStyle((prev: any) => ({
          ...prev,
          transform: `translate(${81 + 20 * latest}%,${22 + 50 * latest}% )`,
          opacity: 1 - 2 * latest,
        }));
        setCourtIconStyle((prev: any) => ({
          ...prev,
          transform: `translate(7%,${19 + 10 * latest}% )`,
          opacity: 1 - 2 * latest,
        }));
        setMaleAvatarBlueStyle((prev: any) => ({
          ...prev,
          transform: `translate(${23 + 10 * latest}%,${18 + 50 * latest}% )`,
          opacity: 1 - 2 * latest,
        }));
        setMaleAvatarPurpleStyle((prev: any) => ({
          ...prev,
          transform: `translate(${12 + 20 * latest}%,${22 + 80 * latest}% )`,
          opacity: 1 - 2 * latest,
        }));
        setFemaleAvatarStyle((prev: any) => ({
          ...prev,
          transform: `translate(${69 - 20 * latest}%, ${20.5 + 50 * latest}%)`,
          opacity: 1 - 2 * latest,
        }));
        setDocumentIconStyle((prev: any) => ({
          ...prev,
          transform: `translate(${21 - 50 * latest}%, ${24 + 10 * latest}% )`,
          opacity: 1 - 2 * latest,
        }));
        setKeyIconStyle((prev: any) => ({
          ...prev,
          transform: `translate(${73 + 10 * latest}%, ${24 + 30 * latest}%)`,
          opacity: 1 - 2 * latest,
        }));
        setChartIconStyle((prev: any) => ({
          ...prev,
          transform: `translate(80%, ${18.5 - 10 * latest}%)`,
          opacity: 1 - 2 * latest,
        }));
        setHomeIconWidth(14);
        setDocumentIconWidth(42);
        setChartIconWidth(14);
        setKeyIconWidth(25);
        setAvatarWidthHeight(7);
      }
    }
  });

  return (
    <Box
      background="#080f16"
      height={{ base: "200vh", md: "220vh" }}
      overflow="hidden"
    >
      <Box ref={ref} position="relative" height="210vh">
        <motion.div
          style={{
            position: "absolute",
            width: "100%",
            inset: 0,
            ...courtIconStyle,
          }}
        >
          <Court width={homeIconWidth} height={{ base: "33px", md: "104px" }} />
        </motion.div>
        <motion.div
          style={{
            position: "absolute",
            width: "100%",
            inset: 0,
            ...homeIconStyle,
          }}
        >
          <HomeIcon
            width={homeIconWidth}
            height={{ base: "31px", md: "104px" }}
          />
        </motion.div>
        <motion.div
          style={{
            position: "absolute",
            width: "100%",
            inset: 0,
            ...maleAvatarBlueStyle,
          }}
        >
          <MaleAvatarBlue
            width={avatarWidthHeight}
            height={avatarWidthHeight}
            fill="none"
          />
        </motion.div>
        <motion.div
          style={{
            position: "absolute",
            width: "100%",
            inset: 0,
            ...maleAvatarPurpleStyle,
          }}
        >
          <MaleAvatarPurple
            width={avatarWidthHeight}
            height={avatarWidthHeight}
            fill="none"
          />
        </motion.div>
        <motion.div
          style={{
            position: "absolute",
            width: "100%",
            inset: 0,
            ...femaleAvatarStyle,
          }}
        >
          <FemaleAvatar
            width={avatarWidthHeight}
            height={avatarWidthHeight}
            fill="none"
          />
        </motion.div>
        <motion.div
          style={{
            position: "absolute",
            width: "100%",
            inset: 0,
            ...documentIconStyle,
          }}
        >
          <DocumentIcon
            width={documentIconWidth}
            height={{ base: 38, md: 120 }}
            fill="none"
          />
        </motion.div>
        <motion.div
          style={{
            position: "absolute",
            width: "100%",
            inset: 0,
            ...chartIconStyle,
          }}
        >
          <GraphIcon
            width={chartIconWidth}
            height={{ base: 38, md: 119 }}
            fill="none"
          />
        </motion.div>
        <motion.div
          style={{
            position: "absolute",
            width: "100%",
            inset: 0,
            ...keyIconStyle,
          }}
        >
          <KeyIcon
            width={keyIconWidth}
            height={{ base: 38, md: 110 }}
            fill="none"
          />
        </motion.div>
        <EllipsisFold scrollYProgress={scrollYProgress} />
        <Hexa1Component scrollYProgress={scrollYProgress} />
        <Hexa2Component scrollYProgress={scrollYProgress} />
        <ContentOne />
        <ContentTwo />
      </Box>
    </Box>
  );
};

export default IntroFold;
