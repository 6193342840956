import { createIcon } from "@chakra-ui/icons";

const WalltLogo = createIcon({
  displayName: "WalltIcon",
  viewBox: "0 0 36 40",
  path: (
    <>
      <path
        d="M.395 12.924v13.482a5.992 5.992 0 0 0 2.991 5.184l11.676 6.743a5.991 5.991 0 0 0 5.987 0l11.675-6.742a5.984 5.984 0 0 0 2.993-5.185V12.924a5.99 5.99 0 0 0-2.993-5.185L21.05.997a5.991 5.991 0 0 0-5.987 0L3.386 7.739a5.984 5.984 0 0 0-2.991 5.185Z"
        fill="url(#walt-logo-a)"
      />
      <path
        d="m18.959 19.57.002-.002a1.494 1.494 0 0 0 .261-.42l-.264.422Zm-.361-14.879a1.488 1.488 0 0 0-1.486-.013l-9.475 5.586c-1.092 1.732-2.184 3.464-3.278 5.196l.014-.004-.004.007v10.105c0 .536.276 1.019.74 1.29a1.481 1.481 0 0 0 1.484.018l.002-.002 3.047-1.707v-6.59l3.538-5.88.189-.106 5.967-3.345V5.983c-.006-.095-.085-.899-.738-1.29v-.002Z"
        fill="#fff"
      />
      <path
        d="M8.032 13.405a1.49 1.49 0 0 1-.842 1.254l-2.817.796-.014.004c1.092-1.732 2.183-3.465 3.276-5.197l.398 3.144Z"
        fill="#7B7D88"
      />
      <path
        d="m25.464 9.338-.002.005v4.43l-5.968 3.345-3.514 5.59-.024 5.71-3.234 1.812h-.003a1.48 1.48 0 0 1-1.484-.015 1.483 1.483 0 0 1-.74-1.291V18.819l.008-.01-.019.004 3.304-5.48 9.498-5.325c.458-.235.991-.222 1.438.039.463.27.74.753.74 1.29v.001Z"
        fill="#fff"
      />
      <path
        d="m31.753 14.05-.002.001V26.65c0 .54-.292 1.038-.763 1.303l-11.953 6.703-.006.003a1.483 1.483 0 0 1-1.482-.02 1.481 1.481 0 0 1-.738-1.29l.024-10.38h-.008l.008-.01v-.003l3.267-5.199c3.134-1.757 6.267-3.512 9.4-5.27l.014-.002.048-.032a1.477 1.477 0 0 1 1.475.02c.463.271.715 1.041.715 1.577h.001Z"
        fill="#fff"
      />
      <path
        d="M16.828 22.969h.008v-.012l-.008.012Zm3.259-5.195-.322.513-.54.861-.264.423-.003.004-.562.896.568-.904.258-.41.543-.866.323-.515-.001-.003v.001Zm-1.693 2.698-.148.236.149-.236h-.001Z"
        fill="#0B0D21"
      />
      <path
        d="M14.046 16.505a1.487 1.487 0 0 1-.842 1.254l-2.701 1.05-.019.005 3.303-5.481.258 3.172Z"
        fill="#95989B"
      />
      <path
        d="M20.623 21.95c-.294.45-.873.57-1.405.651l-2.382.367h-.008l.008-.011 3.264-5.204.092.482.502 2.239c.113.498.21 1.047-.07 1.475Z"
        fill="#BABDC0"
      />
      <path
        opacity=".56"
        d="M.395 12.924v13.482a5.992 5.992 0 0 0 2.991 5.184l11.676 6.743a5.991 5.991 0 0 0 5.987 0l11.675-6.742a5.984 5.984 0 0 0 2.993-5.185V12.924a5.99 5.99 0 0 0-2.993-5.185L21.05.997a5.991 5.991 0 0 0-5.987 0L3.386 7.739a5.984 5.984 0 0 0-2.991 5.185Z"
        fill="url(#walt-logo-b)"
      />
      <defs>
        <linearGradient
          id="walt-logo-a"
          x1="-11.034"
          y1="-2.66"
          x2="60.808"
          y2="52.476"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".1" stopColor="#FDD2FD" />
          <stop offset=".23" stopColor="#E87AF5" />
          <stop offset=".37" stopColor="#9A30CE" />
          <stop offset=".46" stopColor="#7E26BE" />
          <stop offset=".55" stopColor="#651DB1" />
          <stop offset=".63" stopColor="#5015A5" />
          <stop offset=".75" stopColor="#390D98" />
          <stop offset=".86" stopColor="#310B94" />
        </linearGradient>
        <linearGradient
          id="walt-logo-b"
          x1="-11.034"
          y1="-2.66"
          x2="60.808"
          y2="52.476"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".1" stopColor="#FDD2FD" />
          <stop offset=".23" stopColor="#E87AF5" />
          <stop offset=".37" stopColor="#9A30CE" />
          <stop offset=".46" stopColor="#7E26BE" />
          <stop offset=".55" stopColor="#651DB1" />
          <stop offset=".63" stopColor="#5015A5" />
          <stop offset=".75" stopColor="#390D98" />
          <stop offset=".86" stopColor="#310B94" />
        </linearGradient>
      </defs>
    </>
  ),
});

export default WalltLogo;
