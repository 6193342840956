import React, { useEffect } from "react";

import {
  Box,
  Center,
  HStack,
  ResponsiveValue,
  Stack,
  Text,
} from "@chakra-ui/react";
import { foldData } from "../components/Feature/staticData";
import WalltFrom from "../components/WalltForm";

export default function ContactUs() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box sx={{ background: "17192C" }}>
      <Box position="relative" overflow="hidden">
        <Center
          background="#080F16"
          height="850px"
          width={{ base: "150%", md: "120%", lg: "120%" }}
          transform={{
            base: "translate(-17%, -65%)",
            md: "translate(-9%, -60%)",
          }}
          borderRadius="50%"
          alignItems="flex-end"
          padding={{ base: "30% 30%", md: "9% 25%" }}
        >
          <Stack
            alignItems="center"
            textAlign="center"
            gap={{ base: 10, md: 8 }}
          >
            <Text
              fontSize={{ base: "34px", md: "60px" }}
              sx={{ fontWeight: "bold" }}
              color="white"
            >
              <Text as="span" color="#1BEBE7">
                Get in touch
              </Text>{" "}
              with us
            </Text>
          </Stack>
        </Center>
      </Box>
      <Stack gap={{ base: 10, md: 5 }} mt={{ base: "-130%", md: "-35%" }}>
        <WalltFrom showHeading={false} />
      </Stack>
    </Box>
  );
}
