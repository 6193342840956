import { createIcon } from "@chakra-ui/icons";

const CancerIcon = createIcon({
  displayName: "CancerIcon",
  viewBox: "0 0 78 122",
  path: (
    <>
      <g opacity=".2" fill="#3C3B6E">
        <path d="M.419 102.992c-.767.74-.43 2.03.599 2.313l10.084 2.786c.56.158.963.647 1.006 1.224l.85 11.303c.092 1.236 1.632 1.736 2.426.789A1361.323 1361.323 0 0 0 35.82 96.559a891.953 891.953 0 0 1-12.99-16.843C13.795 89.768 5.42 98.153.42 102.986v.006ZM54.85 71.793c8.744-11.924 15.052-21.562 18.779-28.669 1.268-2.46 1.687-5.414 1.192-8.37-.969-5.54-3.456-15.378-9.638-25.51-1.116 12.222-8.843 27.999-23.079 47.024A314.67 314.67 0 0 0 54.85 71.794v-.001Z" />
        <path d="M77.58 103.035c-20.718-19.661-63.4-66.86-64.792-93.82-6.16 10.139-8.648 20-9.638 25.562-.495 2.933-.076 5.91 1.192 8.37 10.993 20.897 41.429 58.36 58.218 78.217.805.953 2.35.419 2.421-.827l.637-11.02a1.372 1.372 0 0 1 1.001-1.246l10.367-2.933c1.023-.289 1.36-1.568.599-2.308l-.005.005Z" />
        <path d="M57.233 20.817C59.09 15.98 60.548 9.994 60.341 5.44c-11.7-7.282-28.919-7.254-42.73.098-.17 4.56 1.305 10.557 3.177 15.427 13.268-7.03 25.24-7.08 36.445-.146Z" />
      </g>
    </>
  ),
});

export default CancerIcon;
