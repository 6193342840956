import { Box, Center, Stack, Text, HStack, useToast } from "@chakra-ui/react";
import FormVaultIcon from "../icons/FormVaultIcon";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import emailjs from "@emailjs/browser";
import StyledInput from "./StyledInput";
import StyledTextArea from "./StyledTextArea";
import StyledButton from "./StyledButton";
import { FC } from "react";

interface IFormInput {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  message: string;
}

interface IProps {
  showHeading?: boolean;
}

const WalltFrom: FC<IProps> = ({ showHeading = true }) => {
  const { handleSubmit, control, reset } = useForm<IFormInput>({
    defaultValues: {
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      message: "",
    },
  });
  const toast = useToast();

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    emailjs
      .send("service_5eec9wo", "template_tf0nu82", data as any, {
        publicKey: "4X97BksteCvVuWvbt",
      })
      .then(() => {
        toast({
          title: "Your Details are submitted",
          status: "success",
          position: "bottom",
          isClosable: true,
        });
        reset();
      });
  };

  return (
    <Box background="#17192c">
      {showHeading ? (
        <Stack alignItems="center" paddingTop="7%">
          <Text
            color="white"
            fontSize={{ base: "25px", md: "64px" }}
            fontWeight={600}
          >
            <Text as="span" color="#995EF9">
              Get in touch
            </Text>{" "}
            with us
          </Text>
          {/* <Text color="#CDCAD7" fontSize={{ base: "17px", md: "22px" }}>
          Reach out, let’s create a positive change together
        </Text> */}
        </Stack>
      ) : null}
      <Center p={{ base: "5% 1%", md: "5% 10%" }}>
        <Stack
          direction={{ base: "column", md: "row" }}
          width="95%"
          border="2px solid #0A0D170D"
          background="#FFFFFF0A"
          borderRadius="20px"
          padding={5}
        >
          <Stack
            flex="1"
            color="#FFFFFF"
            gap={10}
            padding={{ base: "20px 10px", md: "20px 30px" }}
          >
            <Text color="white" fontSize="30" fontWeight={600}>
              Have questions? <br /> Talk to us!
            </Text>
            <Text color="#FFFFFF">
              Reach out, let’s create a positive change together
            </Text>
            <Stack gap={3} as="form" onSubmit={handleSubmit(onSubmit)}>
              <HStack gap={3}>
                <Controller
                  name="first_name"
                  control={control}
                  render={({ field }) => (
                    <StyledInput {...field} placeholder="First Name" />
                  )}
                />
                <Controller
                  name="last_name"
                  control={control}
                  render={({ field }) => (
                    <StyledInput {...field} placeholder="Last Name" />
                  )}
                />
              </HStack>
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <StyledInput {...field} placeholder="Email" />
                )}
              />
              <Controller
                name="phone"
                control={control}
                render={({ field }) => (
                  <StyledInput {...field} placeholder="Phone Number" />
                )}
              />
              <Controller
                name="message"
                control={control}
                render={({ field }) => (
                  <StyledTextArea {...field} placeholder="Message" />
                )}
              />
              <StyledButton type="submit">Send</StyledButton>
            </Stack>
          </Stack>
          <Center
            flex="1"
            background="linear-gradient(360deg, rgba(112, 243, 241, 0.1425) 20.4%, rgba(10, 13, 23, 0) 100%)"
            borderRadius="12px"
          >
            <FormVaultIcon
              fontSize={{ base: "250px", md: "440px" }}
              fill="none"
            />
          </Center>
        </Stack>
      </Center>
    </Box>
  );
};

export default WalltFrom;
