import { Box } from "@chakra-ui/react";
import Story from "../components/AboutWallt/Story";
import WalltFrom from "../components/WalltForm";

const About = () => {
  return (
    <Box>
      <Story />
      <Box background="red" position="relative" zIndex={2}>
        <WalltFrom />
      </Box>
    </Box>
  );
};

export default About;
