import {
  Badge,
  Box,
  Center,
  Divider,
  Heading,
  HStack,
  IconButton,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useMotionValueEvent, useScroll } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import KnowMoreCircle from "./KnowMoreCircle";
import { KnowMoreList, slides } from "./staticData";
import Vault from "../../icons/Vault";
import { ReactComponent as Dashboard } from "../../images/Dashboard.svg";
import { Link } from "react-router-dom";
import ForwardArrow from "../../icons/ForwardArrow";
import MaleAvatar from "../../icons/MaleAvatar";
import FemaleBlueAvatar from "../../icons/FemaleBlueAvatar";
import FemalePurpleAvatar from "../../icons/FemalePurpleAvatar";
import { useResponsive } from "../../providers/useMediaProvider";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";

const KnowMoreContent = () => {
  return (
    <Stack
      padding="50px 12% 110px"
      alignItems="center"
      spacing={50}
      style={{
        width: "100%",
        transition:
          "transform 10ms ease-in-out 100ms, opacity 10ms ease-in-out 100ms",
      }}
    >
      <Heading
        color="white"
        fontSize={{ base: "25px", md: 64 }}
        fontWeight={600}
      >
        Know more about{" "}
        <Text as="span" color="#1BEBE7">
          WALLT
        </Text>
      </Heading>
      <HStack
        gap={{ base: "40px 60px", md: "140px 10%" }}
        justifyContent="space-evenly"
        flexWrap="wrap"
      >
        {KnowMoreList.map((item: any, index: number) => (
          <KnowMoreCircle
            className={`know-more-circle-${index}`}
            data={item}
            _hover={{
              transform: "scale(2)",
              alignItems: "flex-start",
              gap: 0,
              overflow: "auto",
            }}
          />
        ))}
      </HStack>
    </Stack>
  );
};

const WalltForYou = () => {
  return (
    <Stack
      color="white"
      height="100vh"
      alignItems="center"
      justifyContent="center"
      fontSize={{ base: "17px", md: 20 }}
      background="#08141c"
      px="15px"
    >
      <Heading
        fontSize={{ base: "24px", md: 70 }}
        fontWeight={600}
        marginBottom={{ base: "40px", md: "80px" }}
      >
        <Text as="span" color="#1BEBE7">
          WALLT
        </Text>{" "}
        for you
      </Heading>
      <Text marginBottom="40px" textAlign="center">
        “WALLT was created to secure our families and is now being offered as an
        easy-to-use tool to our users to protect their loved ones.”
      </Text>
      <Text>- WALLT Team</Text>
    </Stack>
  );
};

const HorizontalScroll = ({
  setCircleStyle,
  setToggleImage,
  setFemalePurpleAvatarStyle,
  setMaleAvatarStyle,
  setFemaleBlueAvatarStyle,
  scrollYProgress,
}: {
  setCircleStyle: any;
  setToggleImage: any;
  setFemalePurpleAvatarStyle: any;
  setMaleAvatarStyle: any;
  setFemaleBlueAvatarStyle: any;
  scrollYProgress: any;
}) => {
  const { isLargerThan768 } = useResponsive();
  const horizontalRef = useRef(null);
  const [contentOpacity, setContentOpacity] = useState(1);
  const [activeTab, setActiveTab] = useState(1);
  const { scrollXProgress } = useScroll({
    container: horizontalRef,
  });

  useEffect(() => {
    const scrollPosition = scrollXProgress.get();
    const scrollYPosition = scrollYProgress.get();
    let interval: any;
    if (scrollYPosition > 0.65) {
      if (scrollPosition > 0.6) {
        scrollXProgress.set(0);
      } else {
        interval = setInterval(() => {
          scrollXProgress.set(scrollPosition + 0.2);
        }, 10000);
      }
    } else {
      return interval?.clear();
    }
  }, [scrollXProgress, scrollYProgress]);

  // horizontal scroll
  useMotionValueEvent(scrollXProgress, "change", (latest) => {
    if (isLargerThan768) {
      if (latest > 0.7) {
        setFemalePurpleAvatarStyle((prev: any) => ({
          ...prev,
          opacity: 0,
        }));
        setMaleAvatarStyle((prev: any) => ({
          ...prev,
          opacity: 0,
        }));
        setFemaleBlueAvatarStyle((prev: any) => ({
          ...prev,
          opacity: 0,
        }));
        setActiveTab(0);
      } else if (latest > 0.4) {
        setFemalePurpleAvatarStyle((prev: any) => ({
          ...prev,
          transform: "translate(33vw, 114vh) scale(1)",
          opacity: 1,
        }));
        setMaleAvatarStyle((prev: any) => ({
          ...prev,
          transform: "translate(35.5vw, 147vh) scale(1)",
          opacity: 1,
        }));
        setFemaleBlueAvatarStyle((prev: any) => ({
          ...prev,
          transform: "translate(31vw, 177vh) scale(2)",
          opacity: 1,
        }));
        setActiveTab(3);
      } else if (latest > 0.2) {
        setFemalePurpleAvatarStyle((prev: any) => ({
          ...prev,
          transform: "translate(33vw, 114vh) scale(1)",
        }));
        setMaleAvatarStyle((prev: any) => ({
          ...prev,
          transform: "translate(35.5vw, 147vh) scale(2)",
        }));
        setFemaleBlueAvatarStyle((prev: any) => ({
          ...prev,
          transform: "translate(31vw, 177vh) scale(1)",
        }));
        setActiveTab(2);
      } else {
        setFemalePurpleAvatarStyle((prev: any) => ({
          ...prev,
          transform: "translate(33vw, 114vh) scale(2)",
        }));
        setMaleAvatarStyle((prev: any) => ({
          ...prev,
          transform: "translate(35.5vw, 147vh) scale(1)",
        }));
        setFemaleBlueAvatarStyle((prev: any) => ({
          ...prev,
          transform: "translate(31vw, 177vh) scale(1)",
        }));
        setActiveTab(1);
      }
    } else {
      if (latest > 0.66) {
        setFemalePurpleAvatarStyle((prev: any) => ({
          ...prev,
          transform: "translate(65vw, 107vh) scale(1)",
          opacity: 1,
        }));
        setMaleAvatarStyle((prev: any) => ({
          ...prev,
          transform: "translate(57vw, 127vh) scale(1)",
          opacity: 1,
        }));
        setFemaleBlueAvatarStyle((prev: any) => ({
          ...prev,
          transform: "translate(15vw, 135vh) scale(2)",
          opacity: 1,
        }));
        setActiveTab(3);
      } else if (latest > 0.33) {
        setFemalePurpleAvatarStyle((prev: any) => ({
          ...prev,
          transform: "translate(65vw, 107vh) scale(1)",
        }));
        setMaleAvatarStyle((prev: any) => ({
          ...prev,
          transform: "translate(57vw, 127vh) scale(2)",
        }));
        setFemaleBlueAvatarStyle((prev: any) => ({
          ...prev,
          transform: "translate(15vw, 135vh) scale(1)",
        }));
        setActiveTab(2);
      } else {
        setFemalePurpleAvatarStyle((prev: any) => ({
          ...prev,
          transform: "translate(65vw, 107vh) scale(2)",
        }));
        setMaleAvatarStyle((prev: any) => ({
          ...prev,
          transform: "translate(57vw, 127vh) scale(1)",
        }));
        setFemaleBlueAvatarStyle((prev: any) => ({
          ...prev,
          transform: "translate(15vw, 135vh) scale(1)",
        }));
        setActiveTab(1);
      }
    }
    if (isLargerThan768) {
      if (latest > 0.73) {
        setCircleStyle({
          transform: "translate(0, 0)",
          width: `${50 + 50 * latest}%`,
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
          borderTopRightRadius: `${100 - 100 * latest}%`,
          borderBottomRightRadius: `${100 - 100 * latest}%`,
        });
        setContentOpacity(0);
        setToggleImage(false);
      } else {
        setCircleStyle({
          transform: "translate(-23%, -10%)",
          width: `50vw`,
          borderRadius: "100%",
          backgroundImage: "var(--polka-bg-image)",
          backgroundPosition: "var(--polka-bg-position)",
          backgroundSize: "var(--polka-bg-size)",
        });
        setContentOpacity(1);
        setToggleImage(true);
      }
    } else {
      setCircleStyle({
        transform: "translate(-19%, -11%)",
        width: `100vw`,
        borderRadius: "100%",
        backgroundImage: "var(--polka-bg-image)",
        backgroundPosition: "var(--polka-bg-position)",
        backgroundSize: "var(--polka-bg-size)",
      });
      setContentOpacity(1);
      setToggleImage(true);
    }
  });

  return (
    <Center
      width={{ base: "95vw", md: "50vw" }}
      height={{ base: "auto", md: "100vh" }}
      mt={{ base: "105%", md: 0 }}
      alignSelf="end"
      marginRight={{ base: 0, md: 20 }}
      position="relative"
      zIndex={1}
      flexDirection="column"
      alignItems={{ base: "left", md: "center" }}
    >
      <HStack spacing={8}>
        {scrollXProgress.get() > 0.2 && scrollXProgress.get() < 0.8 && (
          <IconButton
            variant="text"
            fontSize="40px"
            aria-label="go-left"
            onClick={() => {
              const position = scrollXProgress.get();
              if (position > 0.2) {
                scrollXProgress.set(position - 0.2);
              } else {
                scrollXProgress.set(0);
              }
            }}
          >
            <ChevronLeftIcon />
          </IconButton>
        )}
        <Stack>
          <HStack
            ref={horizontalRef}
            overflowX="scroll"
            className="scroll-none"
            sx={{ scrollbarWidth: 0 }}
          >
            {slides.map((slide: any) => {
              return (
                <Stack
                  paddingBottom={{ base: 50, md: 100 }}
                  spacing={4}
                  flex={{ base: "0 0 100vw", md: "0 0 50vw" }}
                  opacity={contentOpacity}
                >
                  <Badge
                    fontSize="18px"
                    color="white"
                    background="#411E9C"
                    width="fit-content"
                    padding="5px 15px"
                    fontWeight={400}
                    borderRadius={10}
                  >
                    {slide.badge}
                  </Badge>
                  <Heading
                    color="#17192C"
                    as="h3"
                    fontSize={{ base: "20px", md: "30px" }}
                  >
                    {slide.title}
                  </Heading>
                  <Divider
                    borderColor="#411E9C"
                    width="33px"
                    borderBottomWidth="3px"
                  />
                  <Text color="#08141C" fontSize={{ base: "16px", md: "20px" }}>
                    {slide.subTitle}
                  </Text>
                  <Text
                    fontSize={{ base: "16px", md: "22px" }}
                    dangerouslySetInnerHTML={{ __html: slide.content }}
                  />
                </Stack>
              );
            })}
            {isLargerThan768 && <Box flex="0 0 50vw"></Box>}
          </HStack>
          <HStack
            spacing="15px"
            display={activeTab === 0 ? "none" : "flex"}
            alignSelf="flex-start"
          >
            <Box
              width="10px"
              height="10px"
              borderRadius="full"
              background={activeTab === 1 ? "#1BEBE7" : "#353055"}
            />
            <Box
              width="10px"
              height="10px"
              borderRadius="full"
              background={activeTab === 2 ? "#1BEBE7" : "#353055"}
            />
            <Box
              width="10px"
              height="10px"
              borderRadius="full"
              background={activeTab === 3 ? "#1BEBE7" : "#353055"}
            />
          </HStack>
        </Stack>
        {scrollXProgress.get() < 0.8 && (
          <IconButton
            variant="text"
            fontSize="40px"
            aria-label="go-right"
            onClick={() => {
              const position = scrollXProgress.get();
              if (position < 0.8) {
                scrollXProgress.set(position + 0.2);
              } else {
                scrollXProgress.set(1);
              }
            }}
          >
            <ChevronRightIcon />
          </IconButton>
        )}
      </HStack>
    </Center>
  );
};

const CircleComponent = ({
  circleStyle,
  toggleImage,
}: {
  circleStyle: any;
  toggleImage: boolean;
}) => {
  return (
    <Center
      position="absolute"
      height={{
        base: toggleImage ? 373 : "100vh",
        md: toggleImage ? "125vh" : "100vh",
      }}
      background="#08141C"
      zIndex={0}
      sx={{
        ...circleStyle,
        transition: "width 10ms ease-in-out 200ms",
      }}
      overflow="hidden"
    >
      {toggleImage ? (
        <Vault
          width={{ base: 130, md: 317 }}
          height={{ base: 179, md: 433 }}
          fill="none"
          ml={{ base: "35px", md: 0 }}
        />
      ) : (
        <Center>
          <Dashboard width="90vw" height="90vh" />
          <Center
            height="50vh"
            position="absolute"
            width="100%"
            bottom="0"
            background="linear-gradient(180deg, rgba(13, 19, 61, 0) 0%, rgba(13, 19, 61, 0.52) 26.33%, rgba(13, 19, 61, 0.79) 48.48%, rgba(13, 19, 61, 0.91) 65.44%, rgba(13, 19, 61, 0.98) 85.17%, #0D133D 100%)"
          >
            <Stack alignItems="center" textAlign="center">
              <Link to="/feature">
                <Text as="h1" fontSize={{ base: "", md: "52px" }} color="white">
                  Explore Features
                </Text>
                <ForwardArrow color="white" fontSize="80px" />
              </Link>
            </Stack>
          </Center>
        </Center>
      )}
    </Center>
  );
};

const KnowMoreFold = () => {
  const { isLargerThan768 } = useResponsive();

  const knowMoreFoldRef = useRef(null);
  const [circleStyle, setCircleStyle] = useState<any>({
    transform: isLargerThan768
      ? "translate(-23%, -10%)"
      : "translate(-23%, -10%)",
    width: isLargerThan768 ? "50vw" : "100vw",
    borderRadius: "100%",
    backgroundImage: "var(--polka-bg-image)",
    backgroundPosition: "var(--polka-bg-position)",
    backgroundSize: "var(--polka-bg-size)",
  });
  const [toggleImage, setToggleImage] = useState(true);
  const [maleAvatarStyle, setMaleAvatarStyle] = useState<any>({
    fontSize: 150,
    opacity: 0,
    transform: "translate(35.5vw, 147vh) scale(1)",
    transition: "transform 300ms ease-in-out, width 300ms ease-in-out",
  });
  const [femaleBlueAvatarStyle, setFemaleBlueAvatarStyle] = useState<any>({
    fontSize: 120,
    opacity: 0,
    transform: "translate(31vw, 177vh) scale(1)",
    transition: "transform 300ms ease-in-out, width 300ms ease-in-out",
  });
  const [femalePurpleAvatarStyle, setFemalePurpleAvatarStyle] = useState<any>({
    fontSize: 220,
    opacity: 0,
    transform: "translate(33vw, 114vh) scale(2)",
    transition: "transform 300ms ease-in-out, width 300ms ease-in-out",
  });

  const { scrollYProgress } = useScroll({
    target: knowMoreFoldRef,
  });

  useMotionValueEvent(scrollYProgress, "change", (latest) => {
    console.log(latest, "scrollYProgress");
    if (isLargerThan768) {
      if (latest > 0.65) {
        setFemalePurpleAvatarStyle((prev: any) => ({
          ...prev,
          fontSize: 97,
          transform: "translate(33vw, 157vh) scale(2)",
          opacity: toggleImage ? 1 : 0,
        }));
        setMaleAvatarStyle((prev: any) => ({
          ...prev,
          fontSize: 97,
          transform: "translate(35.5vw, 147vh)",
          opacity: toggleImage ? 1 : 0,
        }));
        setFemaleBlueAvatarStyle((prev: any) => ({
          ...prev,
          fontSize: 97,
          transform: "translate(31vw, 177vh)",
          opacity: toggleImage ? 1 : 0,
        }));
      } else if (latest > 0.28) {
        setMaleAvatarStyle((prev: any) => ({
          ...prev,
          fontSize: 150,
          transform: "translate(70vw, 21vh)",
          opacity: 0.4,
        }));
        setFemaleBlueAvatarStyle((prev: any) => ({
          ...prev,
          fontSize: 120,
          transform: "translate(36vw, 69vh)",
          opacity: 0.4,
        }));
        setFemalePurpleAvatarStyle((prev: any) => ({
          ...prev,
          fontSize: 220,
          transform: "translate(19vw, -3vh)",
          opacity: 0.4,
        }));
      } else {
        setMaleAvatarStyle((prev: any) => ({
          ...prev,
          fontSize: 150,
          transform: "translate(120vw, 35vh)",
          opacity: 0,
        }));
        setFemaleBlueAvatarStyle((prev: any) => ({
          ...prev,
          fontSize: 120,
          transform: "translate(30vw, 120vh)",
          opacity: 0,
        }));
        setFemalePurpleAvatarStyle((prev: any) => ({
          ...prev,
          fontSize: 220,
          transform: "translate(-20vw, -20vh)",
          opacity: 0,
        }));
      }
    } else {
      if (latest > 0.6) {
        setFemalePurpleAvatarStyle((prev: any) => ({
          ...prev,
          fontSize: 67,
          transform: "translate(65vw, 107vh) scale(2)",
          opacity: toggleImage ? 1 : 0,
        }));
        setMaleAvatarStyle((prev: any) => ({
          ...prev,
          fontSize: 67,
          transform: "translate(57vw, 127vh)",
          opacity: toggleImage ? 1 : 0,
        }));
        setFemaleBlueAvatarStyle((prev: any) => ({
          ...prev,
          fontSize: 67,
          transform: "translate(15vw, 135vh)",
          opacity: toggleImage ? 1 : 0,
        }));
      } else if (latest > 0.08) {
        setMaleAvatarStyle((prev: any) => ({
          ...prev,
          fontSize: 100,
          transform: "translate(68vw, 22vh)",
          opacity: 0.4,
        }));
        setFemaleBlueAvatarStyle((prev: any) => ({
          ...prev,
          fontSize: 90,
          transform: "translate(30vw, 70vh)",
          opacity: 0.4,
        }));
        setFemalePurpleAvatarStyle((prev: any) => ({
          ...prev,
          fontSize: 140,
          transform: "translate(6vw, 1vh)",
          opacity: 0.4,
        }));
      } else {
        setMaleAvatarStyle((prev: any) => ({
          ...prev,
          fontSize: 100,
          transform: "translate(120vw, 35vh)",
          opacity: 0,
        }));
        setFemaleBlueAvatarStyle((prev: any) => ({
          ...prev,
          fontSize: 70,
          transform: "translate(30vw, 120vh)",
          opacity: 0,
        }));
        setFemalePurpleAvatarStyle((prev: any) => ({
          ...prev,
          fontSize: 170,
          transform: "translate(-20vw, -20vh)",
          opacity: 0,
        }));
      }
    }
  });

  return (
    <Box ref={knowMoreFoldRef} className="third-fold">
      <KnowMoreContent />
      <MaleAvatar
        position="absolute"
        sx={{
          ...maleAvatarStyle,
          zIndex: 2,
        }}
        fill="none"
      />
      <FemaleBlueAvatar
        position="absolute"
        fill="none"
        sx={{
          ...femaleBlueAvatarStyle,
          zIndex: 2,
        }}
      />
      <FemalePurpleAvatar
        position="absolute"
        fill="none"
        sx={{
          ...femalePurpleAvatarStyle,
          zIndex: 2,
        }}
      />
      <WalltForYou />
      <Stack height="100vh" background="#fff" overflowX="hidden">
        <CircleComponent circleStyle={circleStyle} toggleImage={toggleImage} />
        <HorizontalScroll
          setCircleStyle={setCircleStyle}
          setToggleImage={setToggleImage}
          setMaleAvatarStyle={setMaleAvatarStyle}
          setFemalePurpleAvatarStyle={setFemalePurpleAvatarStyle}
          setFemaleBlueAvatarStyle={setFemaleBlueAvatarStyle}
          scrollYProgress={scrollYProgress}
        />
      </Stack>
    </Box>
  );
};

export default KnowMoreFold;
